import styled from "styled-components";

export const StyledBanner = styled.div`
  background-color: ${(props) => props.theme.lightGrey};
  padding: 40px 0;
  text-align:center;

  h4 {
      padding-bottom:10px;
      font-weight:bold;
  }
`;
