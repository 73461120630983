import React from 'react';
import ReactPlayer from "react-player";

import { Link } from "@reach/router";

import Header from "./elements/Header";
import Banner from "./elements/Banner";
import Heading from "./elements/Heading";
import Subheading from "./elements/Subheading";
import DefaultBtn from "./elements/DefaultButton";
import Footer from "./elements/Footer";
import homeVideoMp4 from "./images/leaves-blowing.mp4";
import icon1 from "./images/heart.png";
import icon2 from "./images/gift.png";
import icon3 from "./images/vision.png";
import EK from "./images/Emily-King.jpg";

import {
  StyledHeaderContainer,
  StyledMainContainer,
  StyledFullWidthContainer,
  StyledFullWidthContainerImg,
} from "./styles/StyledHome";
import { Container, Col, Row } from "react-grid-system";

const Home = () => (
  <>
    <StyledHeaderContainer>
      <div className="background-video">
        <ReactPlayer
          url={homeVideoMp4}
          playing
          loop
          muted
          playsinline
          width="100%"
          height="auto"
        />
      </div>
      <Header page="home" />
      <Container>
        <Row>
          <Col md={12}>
            <div className="margin-top-15p margin-bottom-15p">
              <h1 className="heading">Integrative and Person-Centred</h1>
              <h3 className="sub-heading margin-top-10 margin-bottom-60">
                Counselling and Psychotherapy
              </h3>
              <Link to="/contact">
                <DefaultBtn color="white" text="Get in touch" align="center" />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledHeaderContainer>
    <StyledMainContainer>
      <Container>
        <Row>
          <Col md={10} offset={{ md: 1 }}>
            <div className="margin-top-80 align-center">
              Everyone experiences difficulties at some point in their lives,
              even those who we may regard as the strong ones. It can be a
              lonely and often overwhelming time, one of the hardest tasks is
              making the first step to reach out for help, so well done for
              managing to come this far.
            </div>
          </Col>
        </Row>
        <Row className="margin-top-80">
          <Col md={4} className="intro-icons">
            <img src={icon1} className="centered" alt="Heart icon"/>
            <h3 className="margin-top-20 heading">Counselling can help</h3>
            <p className="margin-top-10">
              you come to terms with past and present experiences, it can be
              challenging at times but its benefits can be life changing
            </p>
          </Col>
          <Col md={4} className="intro-icons">
            <img src={icon2} className="centered" alt="Helping hand icon"/>
            <h3 className="margin-top-20 heading">The aim of Counselling</h3>
            <p className="margin-top-10">
              is to provide a safe, secure and non-judgemental environment in
              which any concerns or issues whether past or present can be
              discussed.
            </p>
          </Col>
          <Col md={4} className="intro-icons">
            <img src={icon3} className="centered" alt="eye icon"/>
            <h3 className="margin-top-20 heading">Counselling is not</h3>
            <p className="margin-top-10">
              about giving advice or telling you what to do, it is about
              providing you with the necessary tools to enable you to make
              informed choices and decisions that are right for you.
            </p>
          </Col>
        </Row>
      </Container>
      <StyledFullWidthContainer className="margin-top-80">
        <Container>
          <Row>
            <Col md={8} style={{ paddingTop: "10%", paddingBottom: "10%" }}>
              <Heading text="About Me" />
              <Subheading text="And my Qualifications" />
              <p className="margin-top-30">
                I am a fully qualified and insured Exeter based Integrative
                Counsellor, I am registered with the BACP (British Association
                of Counselling and Psychotherapy). I hold the following
                qualifications: BA (Hons) Degree in Integrative Counselling,
                Foundation (FdA) Degree in Integrative Counselling and
                Psychotherapy, and a Certificate in Counselling (Level 3).
              </p>
              <p className="margin-top-20">
                I started my Counselling career after experiencing my own
                battles with my mental health and anxiety during my teenage
                years, Counselling benefited me hugely and I want to use my own
                lived experiences to help others.
              </p>
              <p className="margin-top-20">
                I firmly believe in giving my clients ownership of the
                therapeutic process and will adapt my approaches and suggest
                ways of working that are aligned with your lived experiences and
                therapeutic goals. Empathic, attuned and non-judgemental, I aim
                to offer an experienced safe holding for anyone considering
                therapy.
              </p>
            </Col>
          </Row>
        </Container>
        <div className="full-width-img-third">
          <img src={EK} alt="Emily King, Integrative Counsellor" />
        </div>
      </StyledFullWidthContainer>
      <StyledFullWidthContainerImg>
        <Container>
          <Row>
            <Col md={4} style={{ minHeight: 110 }} className="hideCol">
              &nbsp;
            </Col>
            <Col
              md={8}
              style={{ paddingTop: "10%", paddingBottom: "10%" }}
              justify="center"
            >
              <Heading text="About Me" />
              <Subheading text="And my Approach" />
              <p className="margin-top-30">
                Core to my approach is the relationship that I establish with my
                clients, a safe and supportive platform serving as a springboard
                for growth and healing.
              </p>
              <p className="margin-top-20">
                I have extensive experience of working with people who are
                experiencing difficulties with Anxiety and Depression, having
                worked as a senior Mental Health Support Worker for two years
                and alongside CAMHS and Early Help teams for a year and a half.
              </p>
              <p className="margin-top-20">
                However, that is not to say that I only work with clients
                experiencing Anxiety and Depression, the Counselling I provide
                is open for everyone.
              </p>
            </Col>
          </Row>
        </Container>
      </StyledFullWidthContainerImg>
    </StyledMainContainer>
    <Footer />
  </>
);

export default Home;